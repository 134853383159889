import { EInputType } from "src/components/Form/inputTypes";

/**
 * Returns true when valid.
 *
 * Valid when value is not null or
 * follows requirement by input type.
 *
 * eg. String input is not an empty string.
 */

export function validateRequired(value: unknown, type: EInputType): boolean {
  if (value == null) {
    return false;
  }
  switch (type) {
    case EInputType.TEXT:
      if ((value as string).trim().length === 0) {
        return false;
      }
      break;

    case EInputType.SELECTABLE_LIST:
      if ((value as string | unknown[]).length === 0) {
        return false;
      }
      break;

    case EInputType.FIELD_ARRAY:
    case EInputType.FIELDS_ARRAY:
    case EInputType.FILE_ARRAY:
      if ((value as unknown[]).length === 0) {
        return false;
      }
      break;

    case EInputType.NUMBER:
      if (typeof value !== "number") {
        return false;
      }
      break;

    case EInputType.CHECKBOX:
      return value as boolean;
      break;
    default:
  }

  return true;
}
