import { IconButton, InputAdornment } from "@mui/material";
import { useTranslations } from "next-intl";

import { BasicInput } from "../BasicInput";
import type { IBasicInputProps } from "../BasicInput";

import { NewPasswordHelper } from "./NewPasswordHelper";

import { useToggle } from "src/hooks/useToggle";
import { EIcon, Icon } from "src/components/Icon";

export interface IPasswordInputProps extends IBasicInputProps {
  withNewPasswordHelper?: boolean;
  name: string;
}

export function PasswordInput({
  withNewPasswordHelper,
  ...props
}: IPasswordInputProps): JSX.Element {
  const t = useTranslations("INPUTS.passwordInput");
  const [showValue, toggleShowValue] = useToggle(false);

  return (
    <BasicInput
      {...props}
      type={showValue ? "text" : "password"}
      inputProps={{
        maxLength: "100",
      }}
      helper={
        withNewPasswordHelper
          ? ({ value }) => <NewPasswordHelper id={props.name} value={value} />
          : undefined
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label={t("togglePasswordVisibility")}
            onClick={toggleShowValue}
            edge="end"
            size="large"
          >
            <Icon
              icon={showValue ? EIcon.SHOW_PASSWORD : EIcon.HIDE_PASSWORD}
            />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}
