import {
  Stack,
  Box,
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { FormikContextType } from "formik";
import { useTranslations } from "next-intl";

import { HttpError } from "src/utilities/HttpError";
import { ButtonOtp } from "src/components/Input/ButtonOtp/index";
import { TwoFactor } from "src/components/ColoredIcons/TwoFactor";
import { Form, IFormProps } from "src/components/Form";
import { mutateToPath } from "src/utilities/mutateFn";
import { IAuthenticationResponse } from "types/authenticationResponse";
import { OTP } from "src/components/Input/OTP";
import { FormikField } from "src/components/Input/FormikInput";
import { useIsMobile } from "src/hooks/useIsMobile";

interface IConfirmEmailFormFields {
  otp: string;
}

interface IConfirmEmailMutationData {
  otp: string;
  email: string;
}

interface IConfirmEmailModalProps {
  isOpen: boolean;
  email: string;
}

interface IResendOtpProps {
  email: string;
}

export function ConfirmEmailModal({
  isOpen,
  email,
}: IConfirmEmailModalProps): JSX.Element {
  const router = useRouter();
  const t = useTranslations("CONFIRM_EMAIL");

  const initialValues: IFormProps<IConfirmEmailFormFields>["initialValues"] = {
    otp: "",
  };

  const { mutateAsync: submitFormMutate } = useMutation<
    IAuthenticationResponse,
    unknown,
    IConfirmEmailMutationData
  >(
    mutateToPath<IAuthenticationResponse, IConfirmEmailMutationData>(
      "/vision/users/verifyUser"
    )
  );

  const { mutateAsync: resendOtpMutate } = useMutation<
    unknown,
    unknown,
    IResendOtpProps
  >(mutateToPath("/vision/users/resendVerification"));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleChange(
    values: IConfirmEmailFormFields,
    formik: FormikContextType<IConfirmEmailFormFields>
  ) {
    if (values.otp.length !== 6) {
      return;
    }
    formik.setFieldTouched("otp");

    setIsLoading(true);
    try {
      await submitFormMutate({
        ...values,
        email,
      });

      router.push("/dashboard");
    } catch (error) {
      if ((error as HttpError).code === "CodeMismatchException") {
        formik.setFieldValue("otp", "", false);
        formik.setFieldError("otp", t("invalidCodeEntered"));
      } else {
        throw error;
      }
    }
    setIsLoading(false);
  }

  async function handleResendOtp() {
    await resendOtpMutate({ email });
  }

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogContent>
        <Stack alignItems="center" spacing={3}>
          <Box sx={{ maxWidth: "13.625rem", width: "100%" }}>
            <TwoFactor />
          </Box>

          <Stack alignItems="center" spacing={2}>
            <Stack alignItems="center">
              <Typography
                component="h2"
                variant="h1"
                id="form-dialog-title"
                textAlign="center"
              >
                {t("oneStepAwayFromAccessing")}
              </Typography>

              <Typography
                component="p"
                variant="subtitle1"
                color="textSecondary"
                textAlign="center"
              >
                {t("enterDigitCode", { email })}
              </Typography>
            </Stack>

            <Form<IConfirmEmailFormFields>
              initialValues={initialValues}
              onChange={handleChange}
              disableButtons
            >
              <FormikField
                alwaysShowError
                name="otp"
                label=""
                numInputs={6}
                shouldAutoFocus
                component={OTP}
              />
            </Form>
          </Stack>
          {isLoading ? (
            <CircularProgress size="1.15rem" thickness={6} />
          ) : (
            <ButtonOtp
              onClick={handleResendOtp}
              buttonText={t("buttonOtp.resendCode")}
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
