import { styled } from "@mui/material/styles";

const PREFIX = "TwoFactor";

const classes = {
  svg: `${PREFIX}-svg`,
  frame: `${PREFIX}-frame`,
  doorLines: `${PREFIX}-doorLines`,
  doorColor: `${PREFIX}-doorColor`,
};

const Root = styled("svg")(({ theme }) => {
  return {
    [`& .${classes.svg}`]: {
      enableBackground: "new 0 0 276 276",
    },
    [`& .${classes.frame}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
      strokeLinecap: "round",
    },
    [`& .${classes.doorLines}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
      strokeLinecap: "square",
    },
    [`& .${classes.doorColor}`]: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.primary.main,
    },
  };
});

export function TwoFactor(): JSX.Element {
  return (
    <Root
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 30 275 200"
      xmlSpace="preserve"
    >
      <path
        className={classes.frame}
        d="M29,224h40V56c0-3.3,2.7-6,6-6h126c3.3,0,6,2.7,6,6v168l0,0h40"
      />
      <path
        className={classes.doorLines}
        d="M80,225V65c0-2.2,1.8-4,4-4h108c2.2,0,4,1.8,4,4v160l0,0"
      />
      <polyline
        className={classes.doorLines}
        points="92,227 92,200 184,200 184,227 "
      />
      <path
        className={classes.doorLines}
        d="M172,139h10c1.1,0,2,0.9,2,2v24c0,1.1-0.9,2-2,2h-10c-1.1,0-2-0.9-2-2v-24
	C170,139.9,170.9,139,172,139z"
      />
      <path
        className={classes.doorColor}
        d="M163,108c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S165.2,108,163,108"
      />
      <path
        className={classes.doorColor}
        d="M132,90c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4s4-1.8,4-4C136,91.8,134.2,90,132,90"
      />
      <path
        className={classes.doorColor}
        d="M143,90L143,90c-2.2,0-4,2-4,4.4v17.3c0,2.4,1.8,4.3,3.9,4.3h4.1V94.4C147,92,145.2,90,143,90"
      />
      <path
        className={classes.doorColor}
        d="M153,90L153,90c-2.2,0-4,2-4,4.4v17.3c0,2.4,1.8,4.3,3.9,4.3h4.1V94.4C157,92,155.2,90,153,90"
      />
      <path
        className={classes.doorColor}
        d="M132,102v-1c-2.2,0-4,1.9-4,4.2v6.6c0,2.3,1.8,4.2,3.9,4.2h4.1v-10.8c0-2.3-1.8-4.2-4-4.2"
      />
      <path
        className={classes.doorColor}
        d="M114.3,90c-2.4,0-4.3,2-4.3,4.4c0,2.4,1.9,4.4,4.3,4.4h3.7v12.9c0,2.4,1.6,4.3,3.9,4.3h4.1V94.5
	c0-2.5-1.7-4.5-4.1-4.5H114.3z"
      />
      <path
        className={classes.doorColor}
        d="M156,146h22c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-22c-1.1,0-2-0.9-2-2v-2
	C154,146.9,154.9,146,156,146z"
      />
      <path
        className={classes.doorColor}
        d="M177,157L177,157c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1v-2
	C176,157.4,176.4,157,177,157z"
      />
    </Root>
  );
}
