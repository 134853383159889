export enum EInputType {
  TEXT = "text",
  SELECTABLE_LIST = "selectableList",
  DATE = "date",
  NUMBER = "number",
  FIELD_ARRAY = "fieldArray",
  FIELDS_ARRAY = "fieldsArray",
  FILE = "file",
  FILE_ARRAY = "fileArray",
  CHECKBOX = "checkbox",
}
