import { FormikConfig, FormikValues } from "formik";
import { useIntl, useTranslations } from "next-intl";

import { EInputType } from "src/components/Form/inputTypes";
import { validateRequired } from "src/components/Form/validation/validateRequired";
import { REQUIRED_MESSAGE_LOCALIZATION_KEY } from "src/components/Form/validation/const";

export type TValidatorTestFunc<
  Value = unknown,
  Values extends FormikValues = FormikValues
> = (
  value: Value,
  values: Values
) => string | null | unknown[] | Promise<string | null | unknown[]>;

export type TFormValidationSchemas<Values extends FormikValues = FormikValues> =
  Record<
    string,
    {
      required?: boolean;
      multiple?: never;
    } & (
      | {
          type: EInputType.DATE;
          validationTests?: TValidatorTestFunc<string, Values>[];
          min?: Date;
          max?: Date;
        }
      | {
          type: EInputType.CHECKBOX;
          validationTests?: TValidatorTestFunc<boolean, Values>[];
        }
      | {
          type: EInputType.TEXT;
          minLength?: number;
          maxLength?: number;
          validationTests?: TValidatorTestFunc<string, Values>[];
        }
      | {
          type: EInputType.NUMBER;
          validationTests?: TValidatorTestFunc<number, Values>[];
        }
      | {
          type: EInputType.SELECTABLE_LIST;
          validationTests?: TValidatorTestFunc<string, Values>[];
          multiple?: never | false;
        }
      | {
          type: EInputType.SELECTABLE_LIST;
          multiple: true;
          validationTests?: TValidatorTestFunc<string[], Values>[];
        }
      | {
          type: EInputType.FIELD_ARRAY;
          validationTests?: TValidatorTestFunc<(string | number)[], Values>[];
        }
      | {
          type: EInputType.FIELDS_ARRAY;
          validationTests?: TValidatorTestFunc<
            Record<string, unknown>[],
            Values
          >[];
          validation?: TFormValidationSchemas<FormikValues>;
        }
      | {
          type: EInputType.FILE;
          validationTests?: TValidatorTestFunc<File>[];
        }
      | {
          type: EInputType.FILE_ARRAY;
          validationTests?: TValidatorTestFunc<File[]>[];
        }
    )
  >;

export function useValidationBuilder<
  Values extends FormikValues = FormikValues
>(
  formValidationSchemas: TFormValidationSchemas<Values>,
  callbackIfErrors?: () => void
): NonNullable<FormikConfig<Values>["validate"]> {
  const t = useTranslations();
  const intl = useIntl();

  return async function validate(values, schema = formValidationSchemas) {
    const allErrors = Object.fromEntries(
      (
        await Promise.all(
          Object.entries(schema).map(async ([id, formValidationSchema]) => {
            const {
              required,
              type,
              validationTests = [],
            } = formValidationSchema;
            const value = values[id];

            if (required && !validateRequired(value, type)) {
              return [id, t(REQUIRED_MESSAGE_LOCALIZATION_KEY)];
            }

            if (type === EInputType.DATE && typeof value === "string") {
              const formattedValue = new Date(value).setHours(0, 0, 0, 0);

              if (
                formValidationSchema.max &&
                formattedValue >
                  new Date(formValidationSchema.max).setHours(0, 0, 0, 0)
              ) {
                return [
                  id,
                  t("INPUTS.errors.maxDate", {
                    maxDate: intl.formatDateTime(
                      new Date(formValidationSchema.max),
                      "date"
                    ),
                  }),
                ];
              }

              if (
                formValidationSchema.min &&
                formattedValue <
                  new Date(formValidationSchema.min).setHours(0, 0, 0, 0)
              ) {
                return [
                  id,
                  t("INPUTS.errors.minDate", {
                    minDate: intl.formatDateTime(
                      new Date(formValidationSchema.min),
                      "date"
                    ),
                  }),
                ];
              }
            }

            const errors: (string | unknown[])[] = [];

            if (type === EInputType.TEXT && typeof value === "string") {
              if (
                formValidationSchema.maxLength &&
                value.length > formValidationSchema.maxLength
              ) {
                return [
                  id,
                  t("INPUTS.errors.maxLength", {
                    maxLength: formValidationSchema.maxLength,
                  }),
                ];
              }

              if (
                formValidationSchema.minLength &&
                value.length < formValidationSchema.minLength
              ) {
                return [
                  id,
                  t("INPUTS.errors.minLength", {
                    minLength: formValidationSchema.minLength,
                  }),
                ];
              }
            }

            if (
              formValidationSchema.type === EInputType.FIELDS_ARRAY &&
              formValidationSchema.validation
            ) {
              const internalErrors = await Promise.all(
                (value as unknown[]).map(async (val) => {
                  return validate(
                    val as Values,
                    formValidationSchema.validation as unknown as TFormValidationSchemas<Values>
                  );
                })
              );
              if (
                internalErrors?.some((err) => {
                  return Object.keys(err).length > 0;
                })
              ) {
                errors.push(...internalErrors);
              }
            }

            if (validationTests) {
              (
                await Promise.all(
                  validationTests.map(
                    (
                      validationTest: TValidatorTestFunc<
                        Values[keyof Values],
                        Values
                      >
                    ) => {
                      return validationTest(value, values);
                    }
                  )
                )
              ).forEach((error) => error != null && errors.push(error));
            }
            return [id, errors];
          })
        )
      ).filter(([, errors]) => errors != null && errors.length !== 0)
    );

    if (callbackIfErrors && Object.keys(allErrors).length > 0) {
      callbackIfErrors();
    }
    return allErrors;
  };
}
