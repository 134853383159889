import { useTranslations } from "next-intl";
import isEmail from "validator/lib/isEmail";

import { TValidatorTestFunc } from "src/components/Form/validation/useValidationBuilder";

export function useValidateEmail(): (
  value: string
) => ReturnType<TValidatorTestFunc<string>> {
  const t = useTranslations("INPUTS");

  return (value) => {
    return isEmail(value) ? null : t("email.errors.invalidFormat");
  };
}
