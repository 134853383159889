import { InputAdornment, FormHelperText } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslations } from "next-intl";

import { Link } from "src/components/Link";
import { Icon, EIcon } from "src/components/Icon";
import { FormikField } from "src/components/Input/FormikInput";
import { PasswordInput } from "src/components/Input/PasswordInput";
import { baseTheme } from "src/theme/base";
import { sendAmplitudeData } from "src/utilities/amplitude";

export interface ISignInForm {
  translation: string;
  email: string;
  forgotPasswordParams?: URLSearchParams;
  tillStaff?: boolean;
}
export default function SignInForm({
  translation,
  email,
  tillStaff,
  forgotPasswordParams,
}: ISignInForm): JSX.Element {
  const t = useTranslations(translation);
  const forgotPasswordUrl = forgotPasswordParams
    ? `?${forgotPasswordParams.toString()}`
    : "";
  const handleforgotPassword = () => {
    if (forgotPasswordParams) {
      return;
    }
    sendAmplitudeData("Sign-in/Click/forgot-password");
  };
  return (
    <>
      {!tillStaff && (
        <Stack spacing={2}>
          <FormikField
            startAdornment={
              <InputAdornment position="start">
                <Icon icon={EIcon.EMAIL} alt="" />
              </InputAdornment>
            }
            name="email"
            label={t("input.emailAddress.text")}
            type="email"
            autoComplete="username"
            placeholder={email ? "" : t("input.emailAddress.placeholder")}
            disabled={!!email}
          />
          <FormikField
            startAdornment={
              <InputAdornment position="start">
                <Icon icon={EIcon.PASSWORD_DOTTED} alt="" />
              </InputAdornment>
            }
            name="password"
            label={t("input.password.text")}
            component={PasswordInput}
            autoComplete="current-password"
            required={!tillStaff}
          />
          <FormHelperText sx={{ textAlign: "right" }}>
            <Link
              href={`/forgot-password${forgotPasswordUrl}`}
              onClick={handleforgotPassword}
              sx={{ color: baseTheme.palette.tillBlue }}
            >
              {t("input.password.forgotPassword")}
            </Link>
          </FormHelperText>
        </Stack>
      )}
    </>
  );
}
