import OtpInput, { OtpInputProps } from "react-otp-input";
import { useTheme } from "@mui/material";
import type { ReactNode } from "react";

import { InputLayout } from "../InputLayout";

export interface IOtpInputProps extends Omit<OtpInputProps, "isDisabled"> {
  label?: string;
  id?: string;
  errorMessage?: string;
  helperMessage?: string;
  disabled?: boolean;
  labelAdornment?: ReactNode;
}

export function OTP({
  label,
  numInputs = 6,
  id,
  errorMessage,
  helperMessage,
  disabled,
  labelAdornment,
  ...props
}: IOtpInputProps): JSX.Element {
  const theme = useTheme();

  return (
    <InputLayout
      id={id}
      label={label as string}
      errorMessage={errorMessage}
      helperMessage={helperMessage}
      labelAdornment={labelAdornment}
    >
      <OtpInput
        numInputs={numInputs}
        containerStyle={{
          margin: theme.spacing(0, -1),
        }}
        focusStyle={{
          outline: "none",
          borderColor: theme.palette.secondary.main,
          borderWidth: "1px",
        }}
        inputStyle={{
          // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
          // ios will zoom in on inputs without this as it thinks the font size is 11px which is its default
          fontSize: "1rem",

          boxSizing: "content-box",

          borderRadius: "2px",
          margin: theme.spacing(0, 1),

          // using maxWidth to control padding in order to support small screens
          padding: "1rem 0",
          maxWidth: `calc(${theme.spacing(2)} * 2 + 0.8rem)`,
          width: `100%`,

          // The component requires these properties are seperated as well as combined
          border: theme.shape.solidBorder,
          borderColor: theme.palette.divider,
          borderWidth: "1px",
          borderStyle: "solid",
        }}
        errorStyle={{
          borderColor: theme.palette.error.main,
        }}
        hasErrored={Boolean(errorMessage)}
        data-testid={id}
        {...props}
        isDisabled={disabled}
        isInputNum
      />
    </InputLayout>
  );
}
