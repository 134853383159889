import { FormHelperText, Typography } from "@mui/material";

import { Link } from "src/components/Link";
import { useTillTranslations } from "src/hooks/i18n/useTillTranslations";
import usePortalConfig from "src/hooks/usePortalConfig";

export interface IAuthDisclaimer {
  translation: string;
}
export default function AuthDisclaimer({
  translation,
}: IAuthDisclaimer): JSX.Element {
  const { t, rich } = useTillTranslations(translation);
  const { data: portalConfig, isFetched } = usePortalConfig();
  const customUrls = isFetched ? portalConfig?.customUrls : {};

  return (
    <FormHelperText sx={{ textAlign: "center" }}>
      {rich("disclaimer.description", {
        p: (children) => <Typography component="span">{children}</Typography>,
        privacyPolicyLink: (privacyPolicy) => (
          <Link
            href={
              customUrls?.privacyPolicyLink ?? t("disclaimer.privacyPolicyLink")
            }
          >
            {privacyPolicy}
          </Link>
        ),
        generalTermsLink: (generalTerms) => (
          <Link
            href={
              customUrls?.generalTermsLink ?? t("disclaimer.generalTermsLink")
            }
            target="_blank"
          >
            {generalTerms}
          </Link>
        ),
      })}
    </FormHelperText>
  );
}
