import { FormHelperText, Grid, Stack, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import type { TValidatorTestFunc } from "src/components/Form/validation/validationBuilder";
import { Icon, EIcon, EIconSize } from "src/components/Icon";

export enum EPasswordErrors {
  MinLength = "MIN_LENGTH",
  MaxLength = "MAX_LENGTH",
  UpperCase = "UPPER_CASE",
  LowerCase = "LOWER_CASE",
  Number = "NUMBER",
  Special = "SPECIAL",
}

const MIN_LENGTH = 8;
const MAX_LENGTH = 100;

type TReturn<Values> = ReturnType<TValidatorTestFunc<string, Values>>;

function validateMinLength<Values>(value: string): TReturn<Values> {
  return value.length < MIN_LENGTH ? EPasswordErrors.MinLength : null;
}

function validateMaxLength<Values>(value: string): TReturn<Values> {
  return value.length > MAX_LENGTH ? EPasswordErrors.MaxLength : null;
}

function validateUpperCase<Values>(value: string): TReturn<Values> {
  return /[A-Z]+/.test(value) ? null : EPasswordErrors.UpperCase;
}

function validateLowerCase<Values>(value: string): TReturn<Values> {
  return /[a-z]+/.test(value) ? null : EPasswordErrors.LowerCase;
}

function validateNumber<Values>(value: string): TReturn<Values> {
  return /\d+/.test(value) ? null : EPasswordErrors.Number;
}

function validateSpecial<Values>(value: string): TReturn<Values> {
  const specialRegex = /[+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+/;

  return specialRegex.test(value) ? null : EPasswordErrors.Special;
}

export const passwordValidations = [
  validateMinLength,
  validateMaxLength,
  validateUpperCase,
  validateLowerCase,
  validateNumber,
  validateSpecial,
];

interface IItemProps {
  id: string;
  isValid: boolean;
  message: string;
}

function Item({ id, isValid, message }: IItemProps): JSX.Element {
  return (
    <Stack spacing={1} direction="row" data-testid={id} alignItems="center">
      <Icon
        size={EIconSize.SM}
        icon={EIcon[isValid ? "TICK_IN_CIRCLE_GREEN" : "CROSS_RED"]}
        alt={isValid ? "Valid" : "Invalid"}
      />
      <Typography component="span">{message}</Typography>
    </Stack>
  );
}

export interface INewPasswordHelperProps {
  id: string;
  value?: string;
}

export function NewPasswordHelper({
  id,
  value,
}: INewPasswordHelperProps): JSX.Element | null {
  const t = useTranslations("INPUTS.password.validations");

  if (!value) {
    return (
      <FormHelperText data-testid={`${id}_description`}>
        {t("mixCharacters", { minLength: MIN_LENGTH })}
      </FormHelperText>
    );
  }

  const errors = passwordValidations.map((passwordValidation) =>
    passwordValidation(value)
  );

  return (
    // wrapping Grid with a div to avoid position issues when placed in side a Stack
    <div>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={1}
        data-testid={`${id}_description`}
      >
        <Grid item>
          <Item
            id={`${id}_min_length`}
            isValid={!errors.includes(EPasswordErrors.MinLength)}
            message={t("minLength", { minLength: MIN_LENGTH })}
          />
        </Grid>
        <Grid item>
          <Item
            id={`${id}_upper_lower`}
            isValid={
              !errors.includes(EPasswordErrors.LowerCase) &&
              !errors.includes(EPasswordErrors.UpperCase)
            }
            message={t("upperLowerCase")}
          />
        </Grid>
        <Grid item>
          <Item
            id={`${id}_number`}
            isValid={!errors.includes(EPasswordErrors.Number)}
            message={t("digits")}
          />
        </Grid>
        <Grid item>
          <Item
            id={`${id}_symbol`}
            isValid={!errors.includes(EPasswordErrors.Special)}
            message={t("symbols")}
          />
        </Grid>
      </Grid>
    </div>
  );
}
