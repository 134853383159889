import React, { ReactElement, useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { EIcon, Icon } from "src/components/Icon";

export interface IButtonOtpProps {
  onClick: () => Promise<void>;
  disabled?: boolean;
  buttonText?: string;
}

const COUNT_DOWN_TIME = 60;

export function ButtonOtp({
  onClick,
  disabled,
  buttonText,
}: IButtonOtpProps): ReactElement {
  const t = useTranslations("VALIDATE_OTP");
  const [disable, setDisable] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(COUNT_DOWN_TIME);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  async function wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async function handleClick() {
    onClick();
    setDisable(true);
    setShowMessage(true);
    await wait(3000);
    setShowTimer(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showTimer && counter > 0) {
        setCounter(counter - 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [showTimer, counter]);

  useEffect(() => {
    if (counter === 0) {
      setDisable(false);
      setShowMessage(false);
      setShowTimer(false);
      setCounter(COUNT_DOWN_TIME);
    }
  }, [counter]);

  const isDisabled = disable || disabled;

  return (
    <Button
      variant="text"
      disabled={isDisabled}
      onClick={handleClick}
      data-testid="test-button"
    >
      {showMessage && !showTimer ? (
        <>
          <Icon icon={EIcon.TICK_GREEN} />
          <Typography
            sx={(theme) => ({ color: theme.palette.tillGrey.dark, pl: 0.5 })}
          >
            {t("verificationCode.success")}
          </Typography>
        </>
      ) : (
        <>
          <Typography>{buttonText || t("buttonOtp.resendCode")}</Typography>
          {showTimer ? (
            <>
              {" "}
              <Typography
                sx={(theme) => ({
                  color: theme.palette.tillGrey.dark,
                  pl: 0.5,
                })}
              >
                ({counter})
              </Typography>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </Button>
  );
}
